<template>
  <v-container>
    <v-card>
      <v-card-title class="headline">
        Story Creator
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="openCreateDialog">
          <v-icon left>mdi-plus</v-icon>
          Create Story
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-row class="mt-0">
        <!-- Left Navigation: List of previous stories -->
        <v-col cols="4" class="pa-6">
          <v-list dense>
            <v-subheader>Previous Stories</v-subheader>
            <v-list-item
              v-for="storyItem in stories"
              :key="storyItem.id"
              @click="selectStory(storyItem)"
              :class="{ 'v-list-item--active': selectedStory && selectedStory.id === storyItem.id }"
            >
              <v-list-item-content>
                <v-list-item-title>{{ storyItem.genre }}</v-list-item-title>
                <v-list-item-subtitle>{{ formatDate(storyItem.createdAt) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-divider vertical></v-divider>
        <!-- Main Panel: Display the selected story -->
        <v-col cols="8" class="pa-6">
          <v-card>
            <v-card-title>
              <span class="headline">
                {{ selectedStory ? selectedStory.genre : 'No Story Selected' }}
              </span>
            </v-card-title>
            <v-card-text>
              <div v-if="selectedStory" v-html="renderedStory"></div>
              <div v-else>
                Please select a story from the left panel or create a new one.
              </div>
            </v-card-text>
            <v-card-actions v-if="selectedStory">
              <v-btn color="primary" text @click="continueStory" :disabled="continuing">
                Continue Story
              </v-btn>
            </v-card-actions>
            <v-alert type="error" v-if="error" dense class="ma-2">
              {{ error }}
            </v-alert>
          </v-card>
        </v-col>
      </v-row>
  
      <!-- Create Story Dialog with Stepper -->
      <v-dialog v-model="createDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Create Your Story</span>
          </v-card-title>
          <v-card-text>
            <v-stepper v-model="step">
              <v-stepper-header>
                <v-stepper-step :complete="step > 1" step="1">
                  Genre/Theme
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 2" step="2">
                  Characters
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="3">
                  World Building
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <!-- Step 1: Genre/Theme -->
                <v-stepper-content step="1">
                  <v-text-field
                    v-model="genre"
                    label="Enter genre or theme"
                    required
                  ></v-text-field>
                  <v-btn color="primary" @click="step++">
                    Next
                  </v-btn>
                </v-stepper-content>
                <!-- Step 2: Characters -->
                <v-stepper-content step="2">
                  <v-text-field
                    v-model="characters"
                    label="Enter characters"
                    required
                  ></v-text-field>
                  <v-btn text @click="step--">
                    Back
                  </v-btn>
                  <v-btn color="primary" @click="step++">
                    Next
                  </v-btn>
                </v-stepper-content>
                <!-- Step 3: World Building -->
                <v-stepper-content step="3">
                  <v-text-field
                    v-model="worldBuilding"
                    label="Describe the world building"
                    required
                  ></v-text-field>
                  <v-btn text @click="step--">
                    Back
                  </v-btn>
                  <v-btn color="primary" @click="generateStory">
                    Generate Story
                  </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
  
            <!-- Loading Indicator & Error Message -->
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="primary"
              class="my-2"
            ></v-progress-linear>
            <v-alert type="error" v-if="error" dense>
              {{ error }}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>
  
<script>
import axios from 'axios'
import marked from 'marked'
import {
  getFirestore,
  collection,
  doc,
  query,
  orderBy,
  onSnapshot,
  setDoc,
  addDoc,
  updateDoc,
  serverTimestamp
} from 'firebase/firestore'

export default {
  name: 'StoryCreator',
  props: ['parent'],
  data() {
    return {
      createDialog: false,
      step: 1,
      genre: '',
      characters: '',
      worldBuilding: '',
      loading: false,
      continuing: false,
      error: '',
      stories: [],
      selectedStory: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      if (!this.parent) return
      this.loadStories()
    },
    openCreateDialog() {
      this.createDialog = true
    },
    loadStories() {
      if (!this.parent || !this.parent.uid) return

      const db = getFirestore()
      const storiesRef = collection(db, 'parents', this.parent.uid, 'stories')
      const q = query(storiesRef, orderBy('createdAt', 'desc'))

      onSnapshot(q, (snapshot) => {
        this.stories = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))

        if (!this.selectedStory && this.stories.length) {
          this.selectedStory = this.stories[0]
        }
      })
    },
    selectStory(story) {
      this.selectedStory = story
    },
    formatDate(timestamp) {
      if (!timestamp) return ''
      const date = timestamp.toDate()
      return date.toLocaleString()
    },
    async generateStory() {
      // Validate input fields.
      if (!this.genre || !this.characters || !this.worldBuilding) {
        this.error = 'Please fill in all fields.'
        return
      }
      this.error = ''
      this.loading = true
  
      try {
        // Call the generateStory Firebase function.
        const response = await axios.post(
          'https://us-central1-kaizen-kids-app.cloudfunctions.net/generateStory',
          {
            genre: this.genre,
            characters: this.characters,
            worldBuilding: this.worldBuilding
          }
        )
        const generatedStory = response.data.completion
  
        // Save the story to the parent's stories sub-collection.
        const db = firebase.firestore()
        await db
          .collection('parents')
          .doc(this.parent.uid)
          .collection('stories')
          .add({
            genre: this.genre,
            characters: this.characters,
            worldBuilding: this.worldBuilding,
            story: generatedStory,
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
          })
  
        // Reset dialog inputs.
        this.loading = false
        this.createDialog = false
        this.step = 1
        this.genre = ''
        this.characters = ''
        this.worldBuilding = ''
      } catch (err) {
        console.error(err)
        this.error = 'Error generating story. Please try again later.'
        this.loading = false
      }
    },
    async continueStory() {
      if (!this.selectedStory) return
      this.continuing = true
      this.error = ''
  
      try {
        // Call the continueStory Firebase function.
        const response = await axios.post(
          'https://us-central1-kaizen-kids-app.cloudfunctions.net/continueStory',
          {
            story: this.selectedStory.story
          }
        )
        const continuation = response.data.completion
        const updatedStory = this.selectedStory.story + '\n\n' + continuation
  
        // Update the story in the parent's stories sub-collection.
        const db = firebase.firestore()
        await db
          .collection('parents')
          .doc(this.parent.uid)
          .collection('stories')
          .doc(this.selectedStory.id)
          .update({
            story: updatedStory
          })
  
        // Update the selected story locally.
        this.selectedStory.story = updatedStory
      } catch (err) {
        console.error(err)
        this.error = 'Error continuing story. Please try again later.'
      }
      this.continuing = false
    }
  },
  computed: {
    renderedStory() {
      // Render markdown (or plain text) to HTML.
      return marked(this.selectedStory ? this.selectedStory.story : '')
    }
  },
  watch: {
    parent() {
      this.init()
    },
  }
}
</script>
  
<style scoped>
.headline {
  font-weight: bold;
}
.v-list-item--active {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
